/*
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.loading {
    height: 41.6px;
    opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
    padding: 0;
}

.ErrorMessage {
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
}

.ErrorMessage svg {
    margin-right: 10px;
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}

.Form {
    animation: fade 200ms ease-out;
}
.InputElement {
    display: block;
    border: none;
    font-size: 18px;
    margin: 10px 0 20px 0;
    max-width: 100%;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
    color: #1c1c1c !important;
    letter-spacing: 0.025em;
}
.InputElement.is-invalid {
    color: red !important;
}
.InputElement {
    color: #1c1c1c !important;
}


.card-element {
    padding: 12px;
    border: 1px solid #00a88b;
    width: 100%;
    margin: 12px 0 0 0;
}